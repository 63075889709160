<template>
  <div class="finances">
    <button
      class="button field is-info is-light"
      @click="isCardModalActive = true"
    >
      <b-icon icon="plus"></b-icon>
      <span>Nova infração</span>
    </button>
    <b-modal v-model="isPaymentDateModalActive" :width="640" scroll="keep">
      <div class="card" style="height: 700px">
        <div class="card-content">
          <div class="content">
            <b-field label="Selecione a data de pagamento">
              <b-datepicker
                placeholder="Clique para selecionar"
                v-model="model.payment_date"
                inline
              >
              </b-datepicker>
            </b-field>
            <b-field>
              <button
                class="button field is-info is-light"
                @click="paymentInfraction"
              >
                <span>Salvar</span>
              </button>
            </b-field>
          </div>
        </div>
      </div>
    </b-modal>
    <b-table
      :data="allInfractions"
      ref="table"
      paginated
      per-page="10"
      :striped="true"
      :debounce-search="1000"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column
        field="number"
        label="Número"
        :searchable="true"
        sortable
        v-slot="props"
      >
        {{ props.row.number || 'N/A' }}
      </b-table-column>

      <b-table-column
        field="date"
        label="Data"
        :searchable="true"
        sortable
        v-slot="props"
      >
        {{ $moment(props.row.date).format('DD/MM/YYYY') }}
      </b-table-column>

      <b-table-column
        field="due_date"
        label="Data de vencimento"
        :searchable="true"
        sortable
        v-slot="props"
      >
        {{ $moment(props.row.due_date).format('DD/MM/YYYY') }}
      </b-table-column>

      <b-table-column
        field="value"
        label="Valor"
        :searchable="true"
        sortable
        v-slot="props"
      >
        R${{ parseFloat(props.row.value).toFixed(2) }}
      </b-table-column>

      <b-table-column
        field="driver"
        label="Motorista"
        :searchable="true"
        sortable
        v-slot="props"
      >
        {{ props.row.driver.name }}
        {{ props.row.driver.last_name }}
      </b-table-column>

      <b-table-column
        field="vehicle"
        label="Veículo"
        :searchable="true"
        sortable
        v-slot="props"
      >
        {{ props.row.vehicle.plate }} ({{
          props.row.vehicle.vehicle_model.model
        }})
      </b-table-column>

      <b-table-column
        field="points"
        label="Pontos"
        :searchable="true"
        sortable
        v-slot="props"
      >
        {{ props.row.points }}
      </b-table-column>

      <b-table-column
        field="type"
        label="Tipo"
        :searchable="true"
        sortable
        v-slot="props"
      >
        {{ props.row.type.toUpperCase() }}
      </b-table-column>

      <b-table-column
        field="payment_date"
        label="Pago"
        sortable
        :searchable="true"
        v-slot="props"
        >{{
          props.row.payment_date
            ? 'SIM (' +
              $moment(props.row.payment_date).format('DD/MM/YYYY') +
              ')'
            : 'NÃO'
        }}</b-table-column
      >

      <b-table-column v-slot="props" label="Opções">
        <button
          class="button is-small is-success"
          @click.prevent="
            onEdit(props.row)
            isPaymentDateModalActive = true
          "
        >
          <b-icon icon="check" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-light"
          @click.prevent="
            onEdit(props.row)
            isCardModalActive = true
          "
        >
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-danger"
          @click.prevent="onDelete(props.row)"
        >
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>{{ props.row.number }}</strong>
                <br />
                <strong>Valor:</strong>
                <small>R$ {{ props.row.price }}</small>
                <br />
                <strong>Data:</strong>
                <small>{{
                  $moment(props.row.date).format('DD/MM/YYYY')
                }}</small>
                <br />
                <strong>Descrição:</strong>
                <small>{{ props.row.description }}</small>
              </p>
            </div>
          </div>
        </article>
      </template>
    </b-table>

    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Número *">
              <b-input type="text" required v-model="model.number"></b-input>
            </b-field>
            <b-field label="Data da multa *">
              <b-datepicker
                ref="datepicker"
                expanded
                v-model="model.date"
                placeholder="Selecione uma data"
              ></b-datepicker>
            </b-field>
            <b-field label="Data de vencimento *">
              <b-datepicker
                ref="datepicker"
                expanded
                v-model="model.due_date"
                placeholder="Selecione uma data"
              ></b-datepicker>
            </b-field>
            <b-field label="Tipo *">
              <b-select
                expanded
                placeholder="Selecione o tipo"
                required
                v-model="model.type"
              >
                <option
                  v-for="(item, index) in [
                    'Leve',
                    'Média',
                    'Grave',
                    'Gravíssima',
                  ]"
                  :key="index"
                  :value="item"
                >
                  {{ item.toUpperCase() }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Motorista *">
              <b-select
                expanded
                placeholder="Selecione o motorista"
                required
                v-model="model.driver_id"
              >
                <option
                  v-for="(item, index) in allDrivers"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name.toUpperCase() }}
                  {{
                    item.nickname ? '(' + item.nickname.toUpperCase() + ')' : ''
                  }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Veículo *">
              <b-select
                expanded
                placeholder="Selecione o veículo"
                required
                v-model="model.vehicle_id"
              >
                <option
                  v-for="(item, index) in allVehicles"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.plate.toUpperCase() }} ({{
                    item.vehicle_model.model.toUpperCase()
                  }})
                </option>
              </b-select>
            </b-field>
            <b-field label="Pontos *">
              <b-input type="number" required v-model="model.points"></b-input>
            </b-field>
            <b-field label="Valor *">
              <b-input
                required
                v-money="moneyFormat"
                v-model="model.value"
              ></b-input>
            </b-field>
            <b-button type="button field is-info" @click="save" class="mt-10"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/infractions/store/service'
import Infraction from '../models/infraction'

export default {
  name: 'Infractions',
  data() {
    return {
      model: Infraction.model,
      isCardModalActive: false,
      isPaymentDateModalActive: false,
      moneyFormat: {
        prefix: 'R$ ',
        sstatefix: '',
        precision: 2,
        masked: false,
      },
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('vehicles', ['allVehicles']),
    ...mapState('drivers', ['allDrivers']),
    ...mapState('infractions', ['allInfractions']),
  },
  methods: {
    ...mapActions('vehicles', ['getAllVehicles']),
    ...mapActions('drivers', ['getAllDrivers']),
    ...mapActions('infractions', ['getAllInfractions']),
    async init() {
      await this.getAllInfractions()
      await this.getAllVehicles()
      await this.getAllDrivers()
    },
    onEdit(item) {
      delete item.vehicle
      delete item.driver
      item.value = parseFloat(item.value).toFixed(2)
      item.date = new Date(this.$moment(item.date))
      item.due_date = new Date(this.$moment(item.due_date))
      item.payment_date = new Date(this.$moment(item.payment_date))
      this.model = { ...item }
    },
    paymentInfraction() {
      if (!this.model.payment_date) return
      this.save()
    },
    async save() {
      const infraction = {
        ...this.model,
      }

      infraction.date = this.$moment(infraction.date).format('YYYY-MM-DD')
      infraction.due_date = this.$moment(infraction.due_date).format(
        'YYYY-MM-DD'
      )
      infraction.value = infraction.value.replace('R$ ', '')
      infraction.value = infraction.value.replace(',', '')

      try {
        const save = infraction.id
          ? await service.updateInfraction(infraction)
          : await service.saveInfraction(infraction)

        await this.$success('Infração')
        location.reload(true)
      } catch (error) {
        this.$error(error.message)
      }
    },
    async onDelete({ id }) {
      try {
        await service.deleteBill(id)
        await this.$delete('Conta')
        location.reload(true)
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
  },
}
</script>

<style scoped></style>
